import api, { setAuthorization } from '../services/api'

const enviarEmailDeAviso = async (params: { email: string, nome: string, token: string, semEmail?: boolean }) => {
    if (params.semEmail) {
        return
    }

    try {
        await api.post('/email/firebase', {
            assunto: "Novos laudos adicionados",
            destinatario: params.email,
            texto: `${params.nome.split(' ')[0]},
Adicionamos os resultados dos seus exames na sua pasta pessoal da ProntoAme Laudo.
Acesse https://laudo.prontoame.com.br e consulte seus laudos.

Att,

ProntoAme
            `
        }, { headers: setAuthorization(params.token) })
    } catch (error) {
        console.log('erro no envio do email')
    }
}

export default enviarEmailDeAviso