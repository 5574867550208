import { cpf } from 'cpf-cnpj-validator'
import { getAnalytics } from "firebase/analytics"
import { initializeApp } from 'firebase/app'
import { Auth, createUserWithEmailAndPassword, getAuth, onAuthStateChanged, signInWithEmailAndPassword, signOut, User } from 'firebase/auth'
import { collection, doc, Firestore, getDocs, getFirestore, query, setDoc, where } from 'firebase/firestore'
import { FirebaseStorage, getStorage } from 'firebase/storage'
import React, { createContext, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import enviarEmailDeCadastro from '../utils/enviarEmailDeCadastro'
import { IPaciente } from '../utils/interfaces'

interface FirebaseContextType {
  user: User | undefined | null,
  auth: Auth | undefined,
  fazerLogin: (params: { email: string, senha: string }) => Promise<boolean>;
  fazerLogout: () => Promise<void>,
  cadastrarPaciente: (paciente: IPaciente & { senha: string, semEmail?: boolean }) => Promise<{ status: boolean, result: string }>;
  firebaseStorage: FirebaseStorage | undefined,
  firebaseFirestore: Firestore | undefined,
  logado: boolean,
  admin: boolean,
  operador: boolean,
}

export const FirebaseContext = createContext<FirebaseContextType>({
  user: undefined,
  auth: undefined,
  fazerLogin: async () => false,
  fazerLogout: async () => { },
  cadastrarPaciente: async () => { return { status: false, result: '' } },
  firebaseStorage: undefined,
  firebaseFirestore: undefined,
  logado: false,
  admin: false,
  operador: false,
})

const firebaseConfig = {
  apiKey: "AIzaSyCCa9EiiINQuSSNUdmNDZl4h8r0yYnLUYU",
  authDomain: "prontoame-laudo.firebaseapp.com",
  projectId: "prontoame-laudo",
  storageBucket: "prontoame-laudo.appspot.com",
  messagingSenderId: "631047585191",
  appId: "1:631047585191:web:7e1c1b3c8f770fb7090a82",
  measurementId: "G-WMJK2B994S"
};

const FirebaseProvider: React.FC = ({ children }) => {
  const firebaseApp = initializeApp(firebaseConfig)
  const firebaseAnalytics = getAnalytics(firebaseApp)
  const firebaseStorage = getStorage(firebaseApp)
  const firebaseFirestore = getFirestore(firebaseApp)
  const navigate = useNavigate()

  const auth = getAuth(firebaseApp)
  const [user, setUser] = useState<User | undefined | null>(undefined)

  onAuthStateChanged(auth, (user) => {
    if (user) {
      setUser(user)
    } else {
      setUser(null)
    }
  })

  const pesquisarPorCpf = async (cpf: string) => {
    const anonimoFirebaseApp = initializeApp(firebaseConfig, "Anonimo")
    const anonimoFirebaseFirestore = getFirestore(anonimoFirebaseApp)
    const anonimoAuth = getAuth(anonimoFirebaseApp)
    await signInWithEmailAndPassword(anonimoAuth, 'anonimo@anonimo.com.br', 'anonimo')
    const q = query(collection(anonimoFirebaseFirestore, 'pacientes'), where('cpf', '==', cpf))
    const querySnapshots = await getDocs(q)
    return querySnapshots
  }

  const fazerLogin = async (params: { email: string, senha: string }) => {
    let login = params.email

    if (cpf.isValid(login)) {
      const querySnapshots = await pesquisarPorCpf(login)
      if (querySnapshots.docs[0]) {
        login = querySnapshots.docs[0].data().email
      } else {
        return false;
      }
    }

    try {
      const userCredential = await signInWithEmailAndPassword(auth, login, params.senha)
      setUser(userCredential.user)
      // console.log(await userCredential.user.getIdToken())
      return true;
    } catch (error: any) {
      setUser(undefined)
      return false;
    }
  }

  const fazerLogout = async () => {
    await signOut(auth)
    setUser(undefined)
    navigate('/')
  }

  const cadastrarPaciente = async (paciente: IPaciente & { senha: string, semEmail?: boolean }) => {
    const { nome, email, senha, cpf, ultimoUpload, semEmail } = paciente

    const querySnapshotsPaciente = await pesquisarPorCpf(cpf)
    if (querySnapshotsPaciente.docs.length) {
      return {
        status: false,
        result: 'CPF já cadastrado'
      }
    }

    try {
      const secondaryApp = initializeApp(firebaseConfig, "Secondary");
      const secondaryAuth = getAuth(secondaryApp)
      const usuario = await createUserWithEmailAndPassword(secondaryAuth, email, senha)
      await setDoc(doc(firebaseFirestore, 'pacientes', usuario.user.uid), {
        uid: usuario.user.uid,
        nome: nome,
        email: email,
        cpf: cpf,
        ultimoUpload: ultimoUpload,
      })

      const token = await user!.getIdToken()      

      enviarEmailDeCadastro({
        email, nome, senha, token, cpf, semEmail
      })

      return {
        status: true,
        result: usuario.user.uid,
      }
    } catch (error) {
      return {
        status: false,
        result: 'Falha na operação. Tente novamente'
      }
    }
  }

  const values: FirebaseContextType = {
    user,
    auth,
    fazerLogin,
    fazerLogout,
    cadastrarPaciente,
    firebaseStorage,
    firebaseFirestore,
    logado: !!user,
    admin: (user) ? !!(user.email!.match(new RegExp('administrador.laudo@prontoame.com.br'))) : false,
    operador: (user) ? !!(user.email!.match(new RegExp('@prontoame.com.br'))) : false
  }

  return (
    <FirebaseContext.Provider value={values}>
      {children}
    </FirebaseContext.Provider>
  );
}

export default FirebaseProvider;