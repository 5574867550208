import { ref, uploadBytes } from '@firebase/storage';
import { Button, Card, CircularProgress, Modal, Typography } from '@mui/material';
import { Box } from '@mui/system';
import React, { useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { useParams, useNavigate, useSearchParams } from 'react-router-dom';
import useFirebase from '../../../hooks/useFirebase';
import { doc, updateDoc } from 'firebase/firestore'
import { format } from 'date-fns';
import enviarEmailDeAviso from '../../../utils/enviarEmailDeAviso';

const ModalAddArquivo: React.FC<{ modalToggle: boolean, handleClose: () => void, carregarArquivos: () => Promise<void> }> = (props) => {
    const [loading, setLoading] = useState<boolean>(false)
    const [files, setFiles] = useState<File[]>([])
    const params = useParams()
    const [searchParams, setSearchParams] = useSearchParams()
    const navigate = useNavigate()
    const { firebaseStorage, firebaseFirestore, user } = useFirebase()

    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        multiple: true, 
        maxFiles: 4, 
        accept: '.pdf',
        onDropAccepted: (files: File[]) => setFiles(files), 
    })    

    const handleSubmit = async () => {
        if (!params.paciente || !firebaseStorage || files.length < 1) return;

        const ultimoUpload = format(new Date(), 'dd/MM/yyyy HH:mm')

        setLoading(true)
        for (const file of files) {
            const sref = ref(firebaseStorage, `laudos/${params.paciente}/${file.name}`)
            await uploadBytes(sref, file)
        }

        try {            
            const pacienteRef = doc(firebaseFirestore!, 'pacientes', params.paciente)
            await updateDoc(pacienteRef, { ultimoUpload })
        } catch (error) {
            console.log('falha na atualização do doc')
        }

        const token = await user!.getIdToken()
        if(searchParams.get('nome') !== '' && searchParams.get('email') !== ''){
            await enviarEmailDeAviso({
                email: searchParams.get('email')!, 
                nome: searchParams.get('nome')!,
                token: token
            })
        }

        setLoading(false)
        setFiles([])        
        props.handleClose()
        setSearchParams({
            nome: searchParams.get('nome') || '',
            email: searchParams.get('email') || '', 
            ultimoUpload: ultimoUpload,
        })
    };

    return (
        <Modal
            open={props.modalToggle}
            onClose={props.handleClose}
            aria-labelledby="modal-add-arquivos"
            aria-describedby="modal para adicionar arquivos"
        >
            <Box
                sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: 400,
                    p: 4,
                    borderRadius: 1,
                }}
            >
                <Card
                    sx={{
                        p: 3,
                    }}
                >
                    <Box                        
                        sx={{
                            border: 'dashed  2px #ccc',
                            borderRadius: 1,
                            height: 100,
                            display: 'flex',
                            alignItems: 'center',
                            p: 4,
                        }}
                        {...getRootProps()}
                    >
                        <input {...getInputProps()} />
                        {
                            isDragActive ?
                                <Typography sx={{ userSelect: 'none', color: '#bbb' }} >Agora solta!!!</Typography> :
                                <Typography sx={{ userSelect: 'none', color: '#bbb' }} >Arraste um arquivo ate aqui ou clique na caixa</Typography>
                        }
                    </Box>
                    <Box
                        sx={{
                            display: 'flex',
                            flexWrap: 'wrap',
                            mt: 1,
                        }}
                    >
                        {(files.length > 0) &&
                            files.map((file, i) => (
                                <Button
                                    key={i}
                                    variant="text"
                                    size='small'
                                    sx={{ my: 0.5, mx: 1 }}
                                >
                                    {file.name}
                                </Button>
                            ))
                        }
                    </Box>
                    <Box sx={{ mt: 2, position: 'relative' }}>
                        <Button
                            fullWidth
                            variant="contained"
                            disabled={loading}
                            onClick={handleSubmit}
                        >
                            Enviar
                        </Button>
                        {loading && (
                            <CircularProgress
                                size={24}
                                sx={{
                                    position: 'absolute',
                                    top: '50%',
                                    left: '50%',
                                    mt: '-12px',
                                    ml: '-12px',
                                }}
                            />
                        )}
                    </Box>
                </Card>
            </Box>
        </Modal>
    );
}

export default ModalAddArquivo;