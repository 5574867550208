import swal from "sweetalert2"
import api, { setAuthorization } from "../services/api"
import gerarPDFdeSenha from "./gerarPDFdeSenha"

const enviarEmailDeCadastro = async (params: { nome: string, email: string, senha: string, token: string, cpf: string, semEmail?: boolean }) => {
    const { nome, email, senha, token, semEmail, cpf } = params

    if (semEmail) {
        const pdf = gerarPDFdeSenha({ cpf, nome, senha })

        try {
            await api.post(
                '/email/firebase',
                {
                    assunto: "Credênciais de acesso do paciente",
                    destinatario: "laboratorio@prontoame.com.br",
                    anexos: [
                        {
                            filename: `acesso-${nome}.pdf`,
                            content: pdf,
                            encoding: 'base64',
                        }
                    ],
                    texto: `
O paciente ${nome} foi cadastrado sem e-mail e por isso esse e-mail foi enviado.
Instrua o paciente a acessar o https://laudo.prontoame.com.br e
fazer o login usando o CPF e a senha informada logo abaixo

senha: ${senha}        

Att,

ProntoAme`
                },
                {
                    headers: setAuthorization(token)
                }
            )
        } catch (error) {
            await swal.fire({
                title: 'Houve um erro ao enviar o e-mail de cadastro para o paciente',
                text: `O laudo do paciente foi cadastrado normalmente, mas houve ao tentar enviar o e-mail.\nSegue a baixo a senha do paciente\nSenha: ${senha}`,
                icon: 'warning',
            })
        }
    } else {
        try {
            await api.post(
                '/email/firebase',
                {
                    assunto: "Cadastro realizado com sucesso",
                    destinatario: email,
                    texto: `${nome},
        você foi cadastrado no site https://laudo.prontoame.com.br
        Acesse e consulte seus laudos.
        Use seu e-mail ou CPF.
        
        senha: ${senha}
        
        Você pode alterar a sua senha no site clicando no botão "Esqueci a senha / Alterar a senha"
        
        Att,
        
        ProntoAme
                  `
                },
                {
                    headers: setAuthorization(token)
                }
            )
        } catch (error) {
            await swal.fire({
                title: 'Houve um erro ao enviar o e-mail de cadastro para o paciente',
                text: `O laudo do paciente foi cadastrado normalmente, mas houve ao tentar enviar o e-mail.\nSegue a baixo a senha do paciente\nSenha: ${senha}`,
                icon: 'warning',
            })
        }
    }

}

export default enviarEmailDeCadastro