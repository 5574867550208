import { Box, Breadcrumbs, Button, ButtonGroup, CircularProgress, Container, InputAdornment, TextField, Typography, useTheme } from '@mui/material';
import { cpf } from 'cpf-cnpj-validator';
import { collection, doc, getDoc, getDocs } from 'firebase/firestore';
import { deleteObject, getDownloadURL, listAll, ref, StorageReference } from 'firebase/storage';
import React, { useEffect, useState } from 'react';
import { MdDelete, MdFileDownload, MdFolder, MdPersonAdd, MdSearch, MdUploadFile } from 'react-icons/md';
import { Link as RouterLink, useParams, useSearchParams } from 'react-router-dom';
import Swal from 'sweetalert2';
import lebasiLogo from '../../assets/images/lebasi.png';
import prontoameLogo from '../../assets/images/prontoame.png';
import Link from '../../components/link';
import useFirebase from '../../hooks/useFirebase';
import { IPaciente } from '../../utils/interfaces';
import ModalAddArquivo from './components/modalAddArquivo';
import ModalAddPaciente from './components/modalAddPaciente';

const HomePage: React.FC = () => {
  const { firebaseStorage, fazerLogout, user, operador, firebaseFirestore, admin } = useFirebase()
  const theme = useTheme()
  const params = useParams()
  const [searchParams, setSearchParams] = useSearchParams()
  const [modalPacienteToggle, setModalPacienteToggle] = useState<boolean>(false)
  const [modalArquivoToggle, setModalArquivoToggle] = useState<boolean>(false)
  const [pastas, setPastas] = useState<IPaciente[]>([])
  const [arquivos, setArquivos] = useState<StorageReference[]>()
  const [pesquisa, setPesquisa] = useState<string>('')
  const [paciente, setPaciente] = useState<IPaciente>()

  useEffect(() => {
    if (!operador) {
      carregarArquivos()
      carregarDadosPaciente()
    }
  }, [])

  useEffect(() => {
    if (params.paciente && operador) {
      carregarArquivos()
    }

    if (!params.paciente && operador) {
      setArquivos([])
      carregarPastas()
    }
  }, [params])

  const carregarPastas = async () => {
    if (firebaseFirestore && operador) {
      const collectionPacientes = collection(firebaseFirestore, 'pacientes')
      const docs = await getDocs(collectionPacientes)            
      const pastas: IPaciente[] = []
      docs.forEach(doc => pastas.push(doc.data() as IPaciente))
      setPastas(pastas)
    }
  }

  const carregarArquivos = async () => {
    if (firebaseStorage) {
      if (params.paciente && admin) {
        const { items } = await listAll(ref(firebaseStorage, `laudos/${params.paciente}`))
        setArquivos(items)
      }

      if (!operador) {
        const uid = user!.uid
        const { items } = await listAll(ref(firebaseStorage, `laudos/${uid}`))
        setArquivos(items)
      }
    }
  }

  const carregarDadosPaciente = async () => {
    if (user && firebaseFirestore) {
      const document = await getDoc(doc(firebaseFirestore, 'pacientes', user.uid))
      if (document.exists()) {
        setPaciente(document.data() as IPaciente)
      }
    }
  }

  const fazerDownlaod = async (arquivo: StorageReference) => {
    const link = await getDownloadURL(arquivo)
    const a = document.createElement('a')
    a.setAttribute('href', link)
    a.setAttribute('target', '_blank')
    a.setAttribute('rel', 'noopener noreferrer')
    a.click()
  }

  const deletarArquivo = async (arquivo: StorageReference) => {
    const res = await Swal.fire({
      icon: 'question',
      title: 'Confirmar ação',
      text: 'Deseja realmente apagar esse arquivo?',
      showConfirmButton: true,
      confirmButtonText: 'Confirmar',
      showDenyButton: true,
      denyButtonText: 'Cancelar'
    })

    if (res.isConfirmed) {
      setArquivos(arquivos!.filter(item => item != arquivo))
      deleteObject(arquivo).catch((error) => {
        Swal.fire({
          icon: 'error',
          title: 'Algo deu errado',
          text: 'Houve um erro ao tentar deletar o arquivo\nTente novamente.'
        })
      })
    }
  }

  const changeModalPacienteToggle = (value?: boolean) => {
    if (value) {
      setModalPacienteToggle(value)
    } else {
      setModalPacienteToggle(!modalPacienteToggle)
    }
  }

  const changeModalArquivoToggle = (value?: boolean) => {
    if (value) {
      setModalArquivoToggle(value)
    } else {
      setModalArquivoToggle(!modalArquivoToggle)
    }
  }

  return (
    <Box
      sx={{
        bgcolor: theme.palette.grey[100],
        width: '100%',
        height: '100vh',
        display: 'flex',
        flexDirection: 'column'
      }}
    >
      {/* Cabecalho */}
      <Box
        sx={{
          bgcolor: theme.palette.grey[200],
          height: '10vh',
          width: 1,
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <Container maxWidth='xl' >
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <Box sx={{ height: '36px' }} >
              <img src={prontoameLogo} height='100%' width='width' />
            </Box>
            <Button onClick={() => fazerLogout()}>
              Sair
            </Button>
          </Box>
        </Container>
      </Box>

      {/* Navegador de arquivos */}
      <Box
        sx={{
          // bgcolor: theme.palette.grey[100],
          height: '90vh',
          width: 1,
          display: 'flex',
          flexDirection: 'column',
          pt: '2vh',
          position: 'relative'
        }}
      >
        <Box
          sx={{
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            height: '60%',
            width: '60%',
            position: 'absolute',
            backgroundImage: `url(${lebasiLogo})`,
            backgroundSize: 'contain',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
            filter: 'grayscale(90%) blur(4px)',
            opacity: 0.15,
          }}
        />
        <Container maxWidth='xl' >
          {/* Navegacao e botoes */}
          {operador &&
            <Box sx={{ display: 'flex', justifyContent: 'space-between' }} >
              <Breadcrumbs aria-label="breadcrumb">
                <Link color="inherit" LinkComponent={RouterLink} to='/'>
                  Laudos
                </Link>
                {(params.paciente && searchParams.get('nome')) &&
                  <Typography color="text.primary">{searchParams.get('nome')}</Typography>
                }
              </Breadcrumbs>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                {(params.paciente &&
                  <Button variant="outlined" startIcon={<MdUploadFile />} sx={{ mr: 1 }} onClick={() => changeModalArquivoToggle()}>
                    Upload
                  </Button>
                )}
                <Button variant="outlined" startIcon={<MdPersonAdd />} sx={{ mr: 1 }} onClick={() => changeModalPacienteToggle()}>
                  Paciente
                </Button>
                <TextField
                  inputProps={{
                    sx: {
                      height: '19.5px',
                    },
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <MdSearch />
                      </InputAdornment>
                    ),
                  }}
                  placeholder='pesquisar...'
                  value={pesquisa}
                  onChange={(event) => setPesquisa(event.target.value)}
                  size='small'
                />
              </Box>
            </Box>
          }
          <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            {(searchParams.get('ultimoUpload')) &&
              <Typography color="gray" sx={{ mx: 1 }} >Última atualização: {searchParams.get('ultimoUpload')}</Typography>
            }
            {(paciente) &&
              <Typography color="gray" sx={{ mx: 1 }} >Última atualização: {paciente.ultimoUpload}</Typography>
            }
            <Box
              sx={{
                display: 'flex',
                flexWrap: 'wrap',
                width: 1,
                py: 2,
              }}
            >
              {(!params.paciente && operador) ?
                pastas.filter((pasta) => {
                  return (pasta.cpf.match(new RegExp(pesquisa, 'i')) || pasta.nome.match(new RegExp(pesquisa, 'i')) || pasta.email.match(new RegExp(pesquisa, 'i')))
                }).map((pasta, i) => (
                  <Box sx={{ m: 1 }} key={i}>
                    <Link LinkComponent={RouterLink} to={`/${pasta.uid}?nome=${pasta.nome}&ultimoUpload=${pasta.ultimoUpload}&email=${pasta.email}`} startIcon={<MdFolder />} variant="outlined" size='large' color='inherit'>
                      {pasta.nome}<br />{cpf.format(pasta.cpf)}<br />{pasta.email}
                    </Link>
                  </Box>
                )) :
                (arquivos) ?
                  (arquivos.length > 0) ?
                    arquivos.filter((arquivo) => arquivo.name.match(new RegExp(pesquisa, 'i'))).map((arquivo, i) => (
                      <Box key={i} sx={{ m: 1 }}>
                        <ButtonGroup>
                          <Button onClick={(event) => fazerDownlaod(arquivo)} variant="outlined" startIcon={<MdFileDownload />} color='inherit' size='large'>
                            {arquivo.name}
                          </Button>
                          {(admin) &&
                            <Button color='error' onClick={(event) => deletarArquivo(arquivo)}>
                              <MdDelete />
                            </Button>
                          }
                        </ButtonGroup>
                      </Box>
                    )) :
                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '50vh', width: '100%' }}>
                      <Typography>{(operador) ? 'Você não tem autorização para ver os arquivos' : 'Não há arquivos'}</Typography>
                    </Box> :
                  <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '50vh', width: '100%' }}>
                    <CircularProgress />
                  </Box>
              }
            </Box>
          </Box>
        </Container>
      </Box>
      <ModalAddPaciente modalToggle={modalPacienteToggle} handleClose={() => changeModalPacienteToggle(false)} />
      <ModalAddArquivo modalToggle={modalArquivoToggle} handleClose={() => changeModalArquivoToggle(false)} carregarArquivos={carregarArquivos} />
    </Box>
  )
}

export default HomePage;