import { MobileDatePicker } from '@mui/lab';
import { Button, Checkbox, CircularProgress, Modal, TextField, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { cpf } from 'cpf-cnpj-validator';
import { format } from 'date-fns';
import { ref, uploadBytes } from 'firebase/storage';
import React, { useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { useNavigate } from 'react-router-dom';
import slugify from "slugify";
import { default as swal, default as Swal } from 'sweetalert2';
import useFirebase from '../../../hooks/useFirebase';
import gerarSenha from '../../../utils/gerarSenha';

const ModalAddPaciente: React.FC<{ modalToggle: boolean, handleClose: () => void }> = (props) => {
    const { firebaseStorage, cadastrarPaciente, admin, user } = useFirebase()
    const [loading, setLoading] = useState(false);
    const [files, setFiles] = useState<File[]>([])
    const [dados, setDados] = useState<{ [key: string]: string }>({ semEmail: "false", email: "" })
    const [data, setData] = useState<Date>(new Date())
    const [cpfValido, setCpfValido] = useState<boolean>()
    const navigate = useNavigate()
    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        multiple: true,
        maxFiles: 4,
        accept: '.pdf',
        onDropAccepted: (files: File[]) => setFiles(files),
    })

    const handleChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const name = event.target.name
        const value = event.target.value
        const nDados = { ...dados, [name]: value }
        setDados(nDados)
        if (nDados['cpf'] && nDados['cpf'].length === 11) {
            setCpfValido(cpf.isValid(nDados['cpf']))
        }
    }

    const handleChangeCheckbox = (event: React.ChangeEvent<HTMLInputElement>) => {
        let email = ""

        if (event.target.checked && !!dados.nome) {
            email = dados.nome.replaceAll(" ", "")
            email = slugify(email, {
                lower: true,
                strict: true,
                locale: 'pt'
            });
            email += "-" + new Date().getTime().toString(36)
            email += "@sememail.com.br"
        }

        let nDados: { [key: string]: string } = { ...dados, semEmail: (event.target.checked) ? "true" : "false" }

        nDados = { ...nDados, email: email }

        setDados(nDados)
    }

    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        const { nome, email, dataNascimento, cpf, semEmail } = dados
        const ultimoUpload = format(new Date(), 'dd/MM/yyyy HH:mm')

        if (!firebaseStorage || files.length < 1 || !cpfValido) return;

        setLoading(true)
        const { status, result } = await cadastrarPaciente({
            nome: nome,
            senha: dataNascimento,
            email: email,
            cpf: cpf,
            uid: '',
            ultimoUpload: ultimoUpload,
            semEmail: (semEmail === "true") ? true : false
        })

        if (status) {
            for (const file of files) {
                const sref = ref(firebaseStorage, `laudos/${result}/${file.name}`)
                await uploadBytes(sref, file)
            }

            const token = await user!.getIdToken()

            setLoading(false)

            setDados({
                semEmail: "false", email: ""
            })

            setFiles([])

            
            props.handleClose()
            
            await Swal.fire("Cadastrado com sucesso", undefined, "success")            

            /* if (admin) {
                navigate(`/${result}?nome=${nome}&ultimoUpload=${ultimoUpload}`)
            } else {
                navigate('/')
            } */

            window.location.href = "/"
        } else {
            setLoading(false)

            setDados({
                semEmail: "false", email: ""
            })

            setFiles([])


            props.handleClose()
            await swal.fire({
                title: 'Falha no cadastro',
                text: result,
                icon: 'error',
            })
        }

    };    

    return (
        <Modal
            open={props.modalToggle}
            onClose={props.handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            sx={{
                overflowY: 'auto',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                p: 1,
            }}
        >
            <Box
                sx={{
                    width: 400,
                    bgcolor: 'background.paper',
                    boxShadow: 24,
                    p: 4,
                    borderRadius: 1,
                }}
            >                
                <Typography id="modal-modal-title" variant="h6" component="h2">
                    Criação da pasta do paciente
                </Typography>
                {
                    (dados.semEmail === "true") ?
                    <Typography id="modal-modal-description" sx={{ mt: 1 }}>
                        Informe o o CPF do paciente.
                        Ele deve usar esses cpf para fazer login.<br />
                        <strong>IMPORTANTE!!! </strong>
                        Uma colaborador designado receberá um e-mail com as credenciais do paciente.<br />
                    </Typography> :
                    <Typography id="modal-modal-description" sx={{ mt: 1 }}>
                        Informe o E-mail e o CPF do paciente.
                        Ele poderá usar esses 2 dados para fazer login.<br />
                        <strong>IMPORTANTE!!! </strong>
                        O cliente receberá um e-mail com a senha.<br />
                    </Typography>
                }                
                <Typography>
                    {dados.email}
                </Typography>
                <Box
                    component='form'
                    onSubmit={handleSubmit}
                >
                    <TextField
                        margin="dense"
                        fullWidth
                        label="Nome Completo"
                        autoFocus
                        id="nome"
                        name="nome"
                        required
                        type='text'
                        onChange={handleChange}
                    />
                    <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                        <Checkbox
                            title='Paciente sem e-mail'
                            onChange={handleChangeCheckbox}
                        />
                        <Typography>
                            Paciente sem e-mail
                        </Typography>
                    </Box>
                    {
                        dados.semEmail === "true" ?
                            <TextField
                                key={"email-a"}
                                margin="dense"
                                fullWidth
                                label="E-mail"
                                id="email"
                                name="email"
                                type='email'
                                value={dados.email}
                                inputProps={{
                                    readOnly: true
                                }}
                            /> :
                            <TextField
                                key={"email-b"}
                                margin="dense"
                                fullWidth
                                label="E-mail"
                                id="email"
                                name="email"
                                required
                                type='email'
                                onChange={handleChange}
                            />
                    }

                    <TextField
                        margin="dense"
                        fullWidth
                        label="CPF"
                        id="cpf"
                        name="cpf"
                        required
                        type='text'
                        error={(cpfValido === false)}
                        inputProps={{
                            maxLength: 11,
                            minLength: 11,
                        }}
                        onChange={handleChange}
                    />
                    <MobileDatePicker
                        label="Data de nascimento"
                        onChange={(data) => {
                            if (data) {
                                setData(data)
                                setDados({ ...dados, dataNascimento: gerarSenha(data) })
                            }
                        }}
                        value={data}
                        maxDate={new Date()}
                        renderInput={(params) => <TextField {...params} required fullWidth margin="dense" />}
                    />
                    <Box
                        sx={{
                            border: 'dashed  2px #ccc',
                            px: 2,
                            borderRadius: 1,
                            height: 100,
                            display: 'flex',
                            alignItems: 'center',
                            mt: 1,
                        }}
                        {...getRootProps()}
                    >
                        <input {...getInputProps()} />
                        {
                            isDragActive ?
                                <Typography sx={{ userSelect: 'none', color: '#bbb' }} >Agora solta!!!</Typography> :
                                <Typography sx={{ userSelect: 'none', color: '#bbb' }} >Arraste um (ou mais) arquivo(s) ate aqui ou clique na caixa</Typography>
                        }
                    </Box>
                    <Box
                        sx={{
                            display: 'flex',
                            flexWrap: 'wrap'
                        }}
                    >
                        {(files.length > 0) &&
                            files.map((file, i) => (
                                <Button
                                    key={i}
                                    variant="text"
                                    size='small'
                                    sx={{ my: 0.1, mx: 0.5 }}
                                >
                                    {file.name}
                                </Button>
                            ))
                        }
                    </Box>
                    <Box sx={{ my: 1, position: 'relative' }}>
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            disabled={loading}
                        >
                            Cadastrar
                        </Button>
                        {loading && (
                            <CircularProgress
                                size={24}
                                sx={{
                                    position: 'absolute',
                                    top: '50%',
                                    left: '50%',
                                    mt: '-12px',
                                    ml: '-12px',
                                }}
                            />
                        )}
                    </Box>
                </Box>
            </Box>
        </Modal>
    );
}

export default ModalAddPaciente;