import jsPDF from "jspdf";
import logoProntoame from '../assets/images/prontoame.png';


const gerarPDFdeSenha = (params: { cpf: string, nome: string, senha: string }) => {
  const { cpf, nome, senha } = params;

  // Cria um novo documento PDF
  const doc = new jsPDF();

  // Insere a logo da empresa (substitua 'logo.png' pelo caminho da sua imagem)
  const logo = new Image();
  logo.src = logoProntoame;

  // Define o tamanho da imagem e a posição
  const imgWidth = 80;
  const imgHeight = (logo.height * imgWidth) / logo.width;
  const marginTop = 10;
  const marginLeft = (doc.internal.pageSize.width - imgWidth) / 2;

  // Adiciona a logo no documento PDF
  doc.addImage(logo, 'PNG', marginLeft, marginTop, imgWidth, imgHeight, "MEDIUM", "MEDIUM");  

  // Adiciona um texto instrutivo
  //const text = `Olá ${nome},\n\nCPF: ${cpf}\nSua senha é: ${senha}`;
  const text = `
Olá ${nome},

você foi cadastrado no site laudo.prontoame.com.br.
Para acessar seus laudos utilize os dados de acesso logo abaixo.

CPF: ${cpf}
Senha: ${senha}

Att,
        
ProntoAme
  `
  doc.text(text, 20, imgHeight + marginTop + 10);

  // Salva o arquivo PDF
  //doc.save(`Senha_${cpf}.pdf`);

  // Exporta o documento PDF para base64
  const buffer = doc.output('arraybuffer');

  let binary = '';
  let bytes = new Uint8Array( buffer );
  let len = bytes.byteLength;
  for (let i = 0; i < len; i++) {
    binary += String.fromCharCode( bytes[ i ] );
  }

  const base64String = window.btoa(binary)

  //Retorna a string base64
  return base64String;
};

export default gerarPDFdeSenha