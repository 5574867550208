import axios from 'axios'

export const setAuthorization = (authorization: string) => {
    return {
        "Content-Type": "application/json",        
        "Authorization": authorization
    }
}

const api = axios.create({
    baseURL: 'https://api.bbscdigital.com.br',
    headers: setAuthorization(''),
})

export default api