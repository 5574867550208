import { createTheme, responsiveFontSizes } from '@mui/material'
import { blue, green } from '@mui/material/colors';

const themeDefault = responsiveFontSizes(createTheme({
  palette: {
    primary: {
      main: '#009191',
    },
    secondary: {
      main: '#2E5EC7',
    },    
  },
  typography: {
    fontFamily: 'Roboto Condensed',
    h1: {
      fontFamily: 'Ubuntu',
    },
    h2: {
      fontFamily: 'Ubuntu',
    },
    h3: {
      fontFamily: 'Ubuntu',
    },
    h4: {
      fontFamily: 'Ubuntu',
    },   
  }    
}))

export default themeDefault