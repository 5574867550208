import { Box } from '@mui/system';
import React from 'react';
import { Route, Routes as ReactRoutes } from "react-router-dom";
import SvgLebasi from '../components/svgs/svgLebasi';
import useFirebase from '../hooks/useFirebase';
import HomePage from '../pages/home/homePage';
import LoginPage from '../pages/login/loginPage';
import NotFound from '../pages/notFound/notFound';

const Routes: React.FC = () => {
  const { logado, user } = useFirebase()

  if(user === undefined){
    return (
      <Box sx={{height: '100vh', width: '100vw', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
        <SvgLebasi />
      </Box>
    )
  }

  return logado ? (
    <ReactRoutes>
      <Route path='/' element={<HomePage />}>
        <Route path=':paciente' element={<HomePage />} />
      </Route>
      <Route path='*' element={<NotFound />} />
    </ReactRoutes>
  ) : (
    <ReactRoutes>
      <Route path='/' element={<LoginPage />} />            
      <Route path='*' element={<NotFound />} />
    </ReactRoutes>
  )
}

export default Routes;