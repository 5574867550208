import { useState } from 'react';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Link from '@mui/material/Link';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import useFirebase from '../../hooks/useFirebase';
import swal from 'sweetalert2'
import ReCAPTCHA from "react-google-recaptcha";
import prontoameLogo from '../../assets/images/prontoame.png'
import { useTheme } from '@mui/material';
import { sendPasswordResetEmail } from 'firebase/auth'

function Copyright(props: any) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {'Copyright © '}
      <Link color="inherit" href="https://bbscdigital.com.br">
        BBSC Digital
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const LoginPage: React.FC = () => {
  const theme = useTheme()
  const { fazerLogin, auth } = useFirebase()
  const [loading, setLoading] = useState<boolean>(false)
  const [recap, setRecap] = useState<boolean>(false)
  const [email, setEmail] = useState<string>('')
  const [senha, setSenha] = useState<string>('')

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (!recap) {
      await swal.fire({
        title: 'Captcha obrigatório',
        text: 'Faltou marcar o ReCAPTCHA',
        icon: 'warning',
      })
      return;
    }

    setLoading(true)

    const result = await fazerLogin({
      email: email,
      senha: senha,
    })

    setLoading(false)
    if (!result) {
      await swal.fire({
        title: 'Usuário ou senhas incorretos',
        text: 'Tente novamente. Em caso de dúvidas, entre em contato',
        icon: 'warning',
      })
    }
  };

  const resetarSenha = async (event: React.MouseEvent<HTMLSpanElement, MouseEvent> | React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    event.preventDefault()
    if(email.trim() === '' || !email.match(new RegExp('@', 'i'))){
      await swal.fire({
        title: 'Preencha o campo de email',
        text: 'Informe o e-mail de acesso que foi usado para o cadastro',
        icon: 'info',
      })
    }

    if(auth){
      await sendPasswordResetEmail(auth, email)
      await swal.fire({
        title: 'E-mail de alteração de senha enviado.',
        text: 'Confira a caixa de entrada do seu e-mail',
        icon: 'success',
      })
    }else{
      await swal.fire({
        title: 'Ops, algo deu errado!',
        text: 'Recarrege a página e tente novamente.',
        icon: 'error',
      })
    }
  }

  return (
    <Grid container component="main" sx={{ height: '100vh' }}>
      <CssBaseline />
      <Grid
        item
        xs={false}
        sm={4}
        md={7}
        sx={{
          backgroundImage: 'url(https://source.unsplash.com/random?happy)',
          backgroundRepeat: 'no-repeat',
          backgroundColor: (t) =>
            t.palette.mode === 'light' ? t.palette.grey[50] : t.palette.grey[900],
          backgroundSize: 'cover',
          backgroundPosition: 'center',
        }}
      />
      <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
        <Box
          sx={{
            my: 8,
            mx: 4,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Box
            sx={{ mx: 4, mb: 2, height: '10vh' }}
          >
            <img src={prontoameLogo} width='100%' height='100%' />
          </Box>
          <Typography component="h1" variant="h4" fontWeight='bold' color='primary'>
            ProntoAme Laudos
          </Typography>
          <Box component="form" onSubmit={handleSubmit} sx={{ mt: 1 }}>
            <TextField
              margin="normal"
              required
              fullWidth
              id="email"
              label="E-mail ou CPF"
              name="email"
              autoComplete="email"
              autoFocus
              type='text'
              value={email}
              onChange={event => setEmail(event.target.value)}
            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="senha"
              label="Senha"
              type="password"
              id="senha"
              autoComplete="senha"
              value={senha}
              onChange={event => setSenha(event.target.value)}
            />
            <Box color={theme.palette.primary.main}>
              <Link color='inherit' href='#' onClick={resetarSenha}>
                Esqueci a senha / Alterar a senha
              </Link>
            </Box>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                mt: 1,
              }}
            >
              <ReCAPTCHA
                sitekey="6LeWtbsdAAAAAKYb2aKEH4_67u1Jzq85K9ivfQJW"
                onChange={(value) => setRecap(!!value)}
              />
            </Box>
            <Box sx={{ mt: 3, mb: 2, position: 'relative' }}>
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                disabled={loading}
              >
                Entrar
              </Button>
              {loading && (
                <CircularProgress
                  size={24}
                  sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    mt: '-12px',
                    ml: '-12px',
                  }}
                />
              )}
            </Box>
            <Copyright sx={{ mt: 4 }} />
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
}

export default LoginPage