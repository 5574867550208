import { CssBaseline, ThemeProvider, Typography } from '@mui/material'
import { LocalizationProvider } from '@mui/lab'
import AdapterDateFns from '@mui/lab/AdapterDateFns'
import { BrowserRouter } from 'react-router-dom'
import FirebaseProvider from './contexts/firebaseProvider';
import themeDefault from './styles/themes/default';
import Routes from './utils/routes';
import { ptBR } from 'date-fns/locale'

function App() {
  return (
    <div className="App">
      <CssBaseline />
      <BrowserRouter>
        <FirebaseProvider>
          <LocalizationProvider dateAdapter={AdapterDateFns} locale={ptBR} >
            <ThemeProvider theme={themeDefault}>
              <Routes />
            </ThemeProvider>
          </LocalizationProvider>
        </FirebaseProvider>
      </BrowserRouter>      
    </div>
  );
}

export default App;
